var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"control-add-new-sidebar","visible":_vm.isControlAddNewSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.clearForm,"change":function (val) { return _vm.$emit('update:is-control-add-new-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" 添加新控件 ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.addNewControl()},"reset":function($event){$event.preventDefault();return _vm.clearForm($event)}}},[_c('validation-provider',{attrs:{"name":"控件名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"控件名称","label-for":"控件名称","state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"id":"new-control-name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null},model:{value:(_vm.newControlData.name),callback:function ($$v) {_vm.$set(_vm.newControlData, "name", $$v)},expression:"newControlData.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"控件描述","label-for":"控件描述"}},[_c('b-form-input',{attrs:{"id":"new-control-description","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newControlData.description),callback:function ($$v) {_vm.$set(_vm.newControlData, "description", $$v)},expression:"newControlData.description"}})],1),_c('validation-provider',{attrs:{"name":"控件类型","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"控件类型","label-for":"控件类型","state":errors.length > 0 ? false : null}},[_c('v-select',{attrs:{"id":"new-control-type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.controlTypeOptions,"reduce":function (option) { return option.value; },"label":"text"},model:{value:(_vm.newControlData.type),callback:function ($$v) {_vm.$set(_vm.newControlData, "type", $$v)},expression:"newControlData.type"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),(_vm.newControlData.type === 'singleSelect' || _vm.newControlData.type === 'multiSelect')?_c('validation-provider',{attrs:{"name":"选项","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"选项","label-for":"选项","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"select-options","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","taggable":"","multiple":"","push-tags":""},model:{value:(_vm.newControlData.options),callback:function ($$v) {_vm.$set(_vm.newControlData, "options", $$v)},expression:"newControlData.options"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}):_vm._e(),_c('b-form-group',{attrs:{"label":"是否必填","label-for":"是否必填"}},[_c('b-form-radio',{attrs:{"name":"必填","value":true},model:{value:(_vm.newControlData.isRequired),callback:function ($$v) {_vm.$set(_vm.newControlData, "isRequired", $$v)},expression:"newControlData.isRequired"}},[_vm._v(" 必填 ")]),_c('b-form-radio',{attrs:{"name":"非必填","value":false},model:{value:(_vm.newControlData.isRequired),callback:function ($$v) {_vm.$set(_vm.newControlData, "isRequired", $$v)},expression:"newControlData.isRequired"}},[_vm._v(" 非必填 ")])],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v("保存 ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" 取消 ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }