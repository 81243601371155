<template>
  <b-sidebar
    id="control-add-new-sidebar"
    :visible="isControlAddNewSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-control-add-new-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          添加新控件
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
          ref="refFormObserver"
        >
        <b-form
          class="p-2"
          @submit.prevent="addNewControl()"
          @reset.prevent="clearForm">
            <validation-provider
              #default="{ errors }"
              name="控件名称"
              rules="required"
            >
              <b-form-group
                label="控件名称"
                label-for="控件名称"
                :state="errors.length > 0 ? false:null"
              >
              <b-form-input
                id="new-control-name"
                v-model="newControlData.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false:null"
              />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

              <b-form-group
                label="控件描述"
                label-for="控件描述"
              >
                <b-form-input
                  id="new-control-description"
                  v-model="newControlData.description"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
              </b-form-group>

            <validation-provider
              #default="{ errors }"
              name="控件类型"
              rules="required"
            >
              <b-form-group
                label="控件类型"
                label-for="控件类型"
                :state="errors.length > 0 ? false : null"
              >
                <v-select
                  id="new-control-type"
                  v-model="newControlData.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="controlTypeOptions"
                  :reduce="option => option.value"
                  label="text"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              v-if="newControlData.type === 'singleSelect' || newControlData.type === 'multiSelect'"
              #default="{ errors }"
              name="选项"
              rules="required"
            >
              <b-form-group
                label="选项"
                label-for="选项"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="select-options"
                  v-model="newControlData.options"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  taggable
                  multiple
                  push-tags
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
            
            <b-form-group
              label="是否必填"
              label-for="是否必填"
            >
              <b-form-radio
                v-model="newControlData.isRequired"
                name="必填"
                :value="true"
              >
                必填
              </b-form-radio>
              <b-form-radio
                v-model="newControlData.isRequired"
                name="非必填"
                :value="false"
              >
                非必填
              </b-form-radio>
            </b-form-group>

            <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >保存
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BSpinner,BBadge,BFormRadio
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'
import { nanoid } from 'nanoid'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isControlAddNewSidebarActive',
    event: 'update:is-control-add-new-sidebar-active',
  },
  props: {
    isControlAddNewSidebarActive: {
      type: Boolean,
      required: true,
    },
    controlTypeOptions: {
      type: Array,
      required: true,
    },
    controls: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      newControlData: {
        id: '',
        name: '',
        description:'',
        type: '',
        options:[],
        isRequired: true,
      },
    }
  },
  methods:{
    clearForm(){
      this.newControlData = {
        id: '',
        name: '',
        description:'',
        type: '',
        options:[],
        isRequired: true,
      },
      this.$refs.refFormObserver.reset()
    },
    addNewControl(){
      if (this.controls.some((control) => control.name === this.newControlData.name)) {
        this.$toast.error('不允许重复的控件名')
        return
      }
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
          this.newControlData.id = nanoid()
          this.controls.push(this.newControlData)
          this.clearForm()
          this.$emit('update:is-control-add-new-sidebar-active', false)
        }
      })
    },
  },
  watch:{
    "newControlData.type":function(val){
      if(val.value !== 'singleSelect' && val.value !== 'multiSelect'){
        this.newControlData.options = []
      }
    }
  },
  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
