<template>
  <b-sidebar
    id="control-edit-sidebar"
    :visible="isWorkflowEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="clearForm"
    @change="(val) => $emit('update:is-workflow-edit-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          编辑流程
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <validation-observer
          ref="refFormObserver"
        >
        <b-form
          class="p-2"
          @submit.prevent="saveEditWorkflow()"
          @reset.prevent="clearForm">
            <validation-provider
              #default="{ errors }"
              name="流程名称"
              rules="required"
            >
              <b-form-group
                label="流程名称"
                label-for="流程名称"
                :state="errors.length > 0 ? false:null"
              >
              <b-form-input
                :disabled="isSystemFixedWorkflow"
                id="new-workflow-name"
                v-model="editingWorkflowCopy.name"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false:null"
              />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="流程描述"
              rules=""
            >
              <b-form-group
                label="流程描述"
                label-for="流程描述"
                :state="errors.length > 0 ? false:null"
              >
              <b-form-input
                id="new-workflow-description"
                v-model="editingWorkflowCopy.description"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false:null"
              />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="审批人"
              rules="required"
            >
              <b-form-group
                label="审批人"
                label-for="审批人"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="new-control-type"
                  v-model="editingWorkflowCopy.members"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userOptions"
                  :selectable="option => ! option.value.includes('select_value')"
                  label="text"
                  multiple
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            
            <b-form-group
              label="审批模式"
              label-for="审批模式"
            >
              <b-form-radio
                v-model="editingWorkflowCopy.mode"
                name="任一人审批"
                value="oneof"
              >
              任一人审批
              </b-form-radio>
              <b-form-radio
                :disabled = "editingWorkflowCopy.members.length < 2"
                v-model="editingWorkflowCopy.mode"
                name="全部审批"
                value="all"
              >
              全部审批
              </b-form-radio>
            </b-form-group>

            <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >保存
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              取消
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,BSpinner,BBadge,BFormRadio
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import store from '@/store'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BFormRadio,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isWorkflowEditSidebarActive',
    event: 'update:is-workflow-edit-sidebar-active',
  },
  props: {
    isWorkflowEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    editingWorkflow:{
      type: Object,
      required: true,
    },
    approvalType:{
      type:String,
      default: 'custom'
    },
    approvalSystemLabel:{
      type:String,
    }
  },
  data() {
    return {
      required,
      editingWorkflowCopy:  {
        id: '',
        name: '',
        description: '',
        members: [],
        mode: 'oneof',
      },
    }
  },
  computed:{
    isSystemFixedWorkflow(){
      if(this.editingWorkflowCopy.id == 'vWCOdoNTmSA2yNjFnHU0c') return true
    }
  },
  methods:{
    clearForm(){
      this.editingWorkflowCopy =  {
        id: '',
        name: '',
        description: '',
        members: [],
        mode: 'oneof',
      },
      this.$refs.refFormObserver.reset()
    },
    saveEditWorkflow(){
      this.$refs.refFormObserver.validate().then((result) => {
        if (result) {
          this.$emit('save-edit-workflow',this.editingWorkflowCopy)
          this.$emit('update:is-workflow-edit-sidebar-active', false)
          this.clearForm()
        }
      })
    },
  },
  watch:{
    "isWorkflowEditSidebarActive": function (val) {
      if (val) {
        this.editingWorkflowCopy = JSON.parse(JSON.stringify(this.editingWorkflow))
      }
    },
    "editingWorkflowCopy.members"(newVal){
      if(this.approvalType == 'system'){
        if(this.approvalSystemLabel == "INVOICE_APPROVAL"){
          if(newVal.length > 1){
            this.editingWorkflowCopy.members.pop()
            this.$toast.error('该审批流仅允许存在一个审批人')
          }
        }
      }
      if(newVal.length < 2){
        this.editingWorkflowCopy.mode = 'oneof'
      }
    },
  },

  setup(props, { emit }) {
    const blankUserData = {
      fullName: '',
      username: '',
      email: '',
      role: null,
      currentPlan: null,
      company: '',
      country: '',
      contact: '',
    }
    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const onSubmit = () => {
      store.dispatch('app-user/addUser', userData.value)
        .then(() => {
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      userData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
