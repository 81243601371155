var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-sidebar',{attrs:{"id":"workflow-add-new-sidebar","visible":_vm.isWorkflowAddNewSidebarActive,"bg-variant":"white","sidebar-class":"sidebar-lg","shadow":"","backdrop":"","no-header":"","right":""},on:{"hidden":_vm.clearForm,"change":function (val) { return _vm.$emit('update:is-workflow-add-new-sidebar-active', val); }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hide = ref.hide;
return [_c('div',{staticClass:"d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"},[_c('h5',{staticClass:"mb-0"},[_vm._v(" 添加新流程 ")]),_c('feather-icon',{staticClass:"ml-1 cursor-pointer",attrs:{"icon":"XIcon","size":"16"},on:{"click":hide}})],1),_c('validation-observer',{ref:"refFormObserver"},[_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.addNewWorkflow()},"reset":function($event){$event.preventDefault();return _vm.clearForm($event)}}},[_c('validation-provider',{attrs:{"name":"流程名称","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"流程名称","label-for":"历路程名称","state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"id":"new-workflow-name","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null},model:{value:(_vm.newWorkflowData.name),callback:function ($$v) {_vm.$set(_vm.newWorkflowData, "name", $$v)},expression:"newWorkflowData.name"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"流程描述","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"流程描述","label-for":"流程描述","state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"id":"new-workflow-description","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null},model:{value:(_vm.newWorkflowData.description),callback:function ($$v) {_vm.$set(_vm.newWorkflowData, "description", $$v)},expression:"newWorkflowData.description"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"审批人","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"审批人","label-for":"审批人","state":errors.length > 0 ? false:null}},[_c('v-select',{attrs:{"id":"new-control-type","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.userOptions,"selectable":function (option) { return ! option.value.includes('select_value'); },"label":"text","multiple":""},model:{value:(_vm.newWorkflowData.members),callback:function ($$v) {_vm.$set(_vm.newWorkflowData, "members", $$v)},expression:"newWorkflowData.members"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":"审批模式","label-for":"审批模式"}},[_c('b-form-radio',{attrs:{"name":"任一人审批","value":"oneof"},model:{value:(_vm.newWorkflowData.mode),callback:function ($$v) {_vm.$set(_vm.newWorkflowData, "mode", $$v)},expression:"newWorkflowData.mode"}},[_vm._v(" 任一人审批 ")]),_c('b-form-radio',{attrs:{"disabled":_vm.newWorkflowData.members.length < 2,"name":"全部审批","value":"all"},model:{value:(_vm.newWorkflowData.mode),callback:function ($$v) {_vm.$set(_vm.newWorkflowData, "mode", $$v)},expression:"newWorkflowData.mode"}},[_vm._v(" 全部审批 ")])],1),_c('div',{staticClass:"d-flex mt-2"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"variant":"primary","type":"submit"}},[_vm._v("保存 ")]),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],attrs:{"type":"button","variant":"outline-secondary"},on:{"click":hide}},[_vm._v(" 取消 ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }